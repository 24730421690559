.main {
  margin: 0px auto;
  padding: 0 150px;
  width: 100%;
  max-width: 1600px;
  min-height: 100vh;
}

/* 800px and down */
@media only screen and (max-width: 800px) {
  .main {
    padding: 0px 50px;
  }
}
