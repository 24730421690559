#featured-projects {
  max-width: 1000px;
  margin-inline: auto;

  .numbered-heading-projects {
    justify-content: start !important;
  }

  .featured-project-card {
    position: relative;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(12, 1fr);
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 100px;

    &:nth-child(odd) {
      .featured-project-content {
        grid-column: 7 / -1;
        text-align: right;
      }
      .featured-project-image {
        grid-column: 1 / 8;
      }
    }
    .featured-project-content {
      position: relative;
      grid-area: 1 / 1 / -1 / 7;
    }

    .featured-project-image {
      grid-area: 1 / 6 / -1 / -1;
      box-shadow: 0 10px 30px -15px var(--navy-shadow);
      position: relative;
      background-color: hsl(166, 100%, 70%);
      transition: all 250ms ease;
      height: 320px;
      .featured-project-image-wrapper {
        background-color: hsl(166, 100%, 70%);
        width: 100%;
        height: 100%;
        mix-blend-mode: multiply;
        filter: grayscale(100%) contrast(1) brightness(90%);
        border-radius: 4px;
        transition: all 250ms ease;

        img {
          object-fit: cover;
        }
        a {
          position: relative;
          overflow: hidden;
          display: inline-block;

          &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            inset: 0;
            z-index: 3;
            background-color: var(--navy);
            mix-blend-mode: screen;
            transition: opacity 250ms ease;
            opacity: 1;
          }
          &:hover {
            &::before {
              opacity: 0;
            }
          }
        }
        &:hover {
          transition: all 250ms ease;
          mix-blend-mode: normal;
          filter: grayscale(0%) contrast(1);
        }
      }
    }
    .featured-project-description {
      box-shadow: 0 10px 30px -15px var(--navy-shadow);
      // transition: var(--transition);
      position: relative;
      z-index: 2;
      padding: 25px;
      border-radius: 4px;
      background-color: var(--light-navy);
      color: var(--light-slate);
      font-size: 17px;
      box-sizing: border-box;
      p {
        text-align: left;
      }
    }

    .featured-project-links {
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      position: relative;
      color: var(--lightest-slate);
      gap: 12px;
    }
    &:nth-child(odd) {
      .featured-project-links {
        -webkit-box-pack: end;
        justify-content: flex-end;
      }
    }

    .featured-project-text {
      color: var(--light-green);
      margin-bottom: 7px;
      margin-top: 0px;
      font-family: mono, sans-serif;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0.2px;
    }
    &:nth-child(odd) {
      .featured-project-tech-list {
        -webkit-box-pack: end;
        justify-content: flex-end;
        &,
        li {
          margin-right: 0;
        }
      }
    }
    &:nth-child(even) {
      .featured-project-tech-list li {
        margin-left: 0;
      }
    }
    .featured-project-tech-list {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      z-index: 2;
      margin: 15px 0px 2px;
      padding: 0px;
      list-style: none;
      font-family: 'mono';
      margin-bottom: 7px;
      li {
        margin: 10px 8px;
        color: var(--light-slate);
        font-size: 13px;
        white-space: nowrap;
      }
    }

    .featured-project-title {
      font-size: clamp(24px, 5vw, 27px);
      margin: 0 0 14px;
      color: var(--lightest-slate);
      font-weight: bold;
      transition: all 250ms ease;

      &:hover {
        color: #64ffda;
        transition: all 250ms ease;
      }
    }
  }
  .numbered-heading-projects {
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: center;
    color: var(--lightest-slate);
    font-family: SansBold;
    font-size: clamp(24px, 5vw, 30px);
    &::before {
      content: '02.';
      height: 100%;
      position: relative;
      margin-right: 10px;
      top: 13px;
      color: var(--light-green);
      font-size: clamp(7px, 3.2vw, 18px);
      font-weight: 400;
      font-family: 'mono';
    }
    &::after {
      content: '';
      display: block;
      height: 1px;
      width: 240px;
      background: var(--slate);
      margin: 1.5rem 0 0.8rem 1rem;
      opacity: 0.3;
    }
  }
}

.project-section {
  height: 100%;
  padding-top: 0;
  .other-noteworthy {
    text-align: center;
    font-weight: bold;
    color: var(--lightest-slate);
    font-size: clamp(24px, 5vw, 27px);
    margin-bottom: 17px;
  }

  .card-body {
    border: none;
    padding: 0;
  }
  .card-title {
    color: var(--lightest-slate);
    font-weight: 600;
    font-family: Sans !important;
  }

  .project-container {
    box-shadow: 0 10px 30px -15px var(--navy-shadow);
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    height: 100%;
    padding: 2rem 1.75rem;
    margin-inline: auto;
    gap: 20px;
  }

  .projects-grid {
    list-style: none;
    padding: 0px;
    margin: 50px 0px 0px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 15px;
    width: 80%;
    margin-inline: auto;
  }
  .card {
    background-color: var(--light-navy);
    width: 20rem;
    height: 16.5rem;
    border: none;
    justify-content: space-between;
  }

  .card-text {
    font-size: 16px;
  }

  .project-inner {
    box-shadow: 0 10px 30px -15px var(--navy-shadow);
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: flex-start;
    position: relative;
    width: 19rem;
    height: 18rem;
    padding: 0 1.75rem 2rem 1.75rem;
    background-color: var(--light-navy);
    cursor: pointer;
    .card-title {
      transition: all 200ms;
    }
  }
  .project-tech-list {
    display: flex;
    align-items: flex-end;
    -webkit-box-flex: 1;
    flex-grow: 1;
    padding: 0px;
    list-style: none;
    flex-direction: row;
    font-size: 13px;
    gap: 1.3rem;
  }

  .project-top {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 21px;
    padding-top: 15px;
    .project-links {
      display: flex;
      flex-direction: row;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      padding: 5px 7px;
      z-index: 35;
      a {
        background-color: transparent;
        border: none;
        color: var(--slate);
        padding: 0 5px;
      }
    }
  }
  .project-folder {
    color: var(--light-green);
  }
  .filter-button {
    left: 80.8%;
    position: relative;
  }
}

.project-inner .card-title {
  transition: color 150ms ease;
}

// card title on-hover
.project-inner:hover .card-title {
  color: var(--light-green);
  transition: color 150ms ease;
}

.project-card {
  display: inline-flex;
  transition: transform 180ms ease;
}
// highlights title when something within project-card is focused
.project-card:focus-within .card-title {
  color: var(--light-green);
  transition: all 180ms ease;
}

.project-card:focus-within {
  transition: transform 180ms ease;
  transform: translateY(-5px);
}

.filtering {
  text-align: center;
  font-size: medium;
  position: relative;
  font-family: mono;
  transform: translate(-10px, 20px);
}

/* Dropdown Button */
.dropbtn {
  background-color: transparent;
  color: var(--light-green);
  padding: 16px;
  font-size: 16px;
  border: 1px solid var(--light-green);
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  left: 0%;
  background-color: var(--light-navy);
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px var(--navy-shadow);
  z-index: 1;
  border: 1px solid var(--navy-shadow);
  .language-img-container {
    $offset: 5px;
    $accumulated-offset: 0px;
    display: inline-block;
    position: relative;
    left: 15px;
    cursor: pointer;
    @for $i from 1 to 6 {
      $accumulated-offset: $accumulated-offset + $offset;
      img:nth-child(#{$i}) {
        position: relative;
        top: 32px;
        z-index: #{$i};
        right: $accumulated-offset;
      }
    }
  }

  /* Links inside the dropdown */
  button {
    color: var(--lightest-slate);
    padding: 12px 16px;
    text-decoration: none;
    display: flex;
    align-items: center;
    flex-direction: column;
    border: none;
    background-color: var(--light-navy);
    font-size: 15px;
    &:not(button:last-of-type)::after {
      content: '';
      position: relative;
      height: 1px;
      width: 101px;
      margin-inline: auto;
      background-color: var(--slate);
      margin-top: 0.8rem;
      opacity: 0.3;
      top: 12px;
    }
    &:not(button:first-of-type) {
      padding-top: 0;
    }
    &:last-of-type {
      padding: 1rem 0 1.8rem 0;
    }
  }
  button:nth-child(1) {
    margin-top: 0.7rem;
  }
  //  JS pic
  button:nth-child(3)::before {
    content: url('../../Assets/JavaScript-logo.png');
    display: inline-block;
    position: relative; /*or absolute*/
    z-index: 100000; /*a number that's more than the modal box*/
    top: 27px;
    left: -62px;
  }

  // PYTHON
  button:nth-child(4)::before {
    content: url('../../Assets/python-logo.png');
    display: inline-block;
    position: relative; /*or absolute*/
    z-index: 100000; /*a number that's more than the modal box*/
    left: -62px;
    top: 29px;
  }

  // NODE
  button:nth-child(5)::before {
    content: url('../../Assets/flutter-logo.png');
    display: inline-block;
    position: relative; /*or absolute*/
    z-index: 100000; /*a number that's more than the modal box*/
    left: -62px;
    top: 25px;
  }

  // LUA
  button:nth-child(6)::before {
    content: url('../../Assets/lua-logo.png');
    display: inline-block;
    position: relative; /*or absolute*/
    z-index: 100000; /*a number that's more than the modal box*/
    left: -62px;
    top: 28px;
  }

  // CSS
  button:nth-child(7)::before {
    content: url('../../Assets/flutter-logo.png');
    display: inline-block;
    position: relative; /*or absolute*/
    z-index: 100000; /*a number that's more than the modal box*/
    left: -62px;
    top: 25px;
  }
}

//  1084px and down
@media only screen and (max-width: 1084px) {
  .project-inner {
    margin-inline: auto;
  }
  .projects p {
    text-align: left;
  }

  .projects-grid {
    justify-content: center;
  }

  ul.projects-grid {
    a {
      width: fill-available;
      width: -webkit-fill-available;
      width: -moz-available;
      margin-inline: auto;
    }
  }
}

@media only screen and (max-width: 950px) {
  .numbered-heading-projects {
    width: 100% !important;
    &::after {
      width: 100% !important;
    }
  }
  .filter-button {
    position: relative;
    left: 71.2% !important;
  }
  .dropdown-content {
    left: -50%;
  }
}

@media only screen and (max-width: 350px) {
  .dropdown-content {
    left: -65%;
  }
}

//   800px and up
@media only screen and (min-width: 800px) {
}

@media (max-width: 768px) {
  #featured-projects {
    .featured-project-card {
      .featured-project-image {
        height: 100%;
        opacity: 0.25;
      }

      .featured-project-content {
        display: flex;
        flex-direction: column;
        -webkit-box-pack: center;
        justify-content: center;
        height: 100%;
        grid-column: 1 / -1;
        padding: 40px 40px 30px;
        z-index: 5;
        grid-column: 1 / -1 !important;
        text-align: left !important;

        .featured-project-description {
          padding: 20px 0;
          background-color: transparent;
          box-shadow: none;
        }

        .featured-project-tech-list {
          justify-content: flex-start !important;
          li {
            font-size: 15px;
            &:first-of-type {
              margin-left: 0;
            }
          }
        }
        .featured-project-links {
          justify-content: flex-start !important;
          margin-top: 7px;
        }
      }
    }
  }

  .featured-project-image {
    grid-column: 1 / -1 !important;
  }
}
