.home {
  margin-inline: auto;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  max-width: 1000px;
  padding: 0px 0px 150px 0px;
  color: var(--slate);
  h3 {
    margin: 10px 0 0 0;
    line-height: 0.9;
  }
  .hi {
    margin-left: 2px;
    font-size: clamp(14px, 5vw, 16px);
    color: var(--light-green);
    font-family: mono, sans-serif;
  }
  .alternating {
    cursor: pointer;
    text-decoration: none;
    position: relative;
    color: var(--light-green);
    transition: color 0.3s ease;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 5px;
      bottom: 12px;
      left: 0;
      background-color: var(--light-green); /* Set the underline color */
      visibility: hidden;
      transform: scaleX(0);
      transition: all 0.3s ease;
    }

    &:hover {
      transition: text-decoration 250ms ease-in-out;
      &::before {
        visibility: visible;
        transform: scaleX(1);
      }
    }
  }

  .about-me-header {
    max-width: 540px;
    line-height: 1.3;
    font-size: 20px;
    margin: 20px 0 0 0;
  }
  .button-header {
    color: var(--light-green);
    background-color: transparent;
    border: 1px solid var(--light-green);
    border-radius: 4px;
    padding: 1.4rem 1.9rem;
    line-height: 1;
    text-decoration: none;
    cursor: pointer;
    margin-top: 50px;
    font-size: 14px;
    letter-spacing: 1.2px;
    font-family: SansBold;
    font-weight: 500;
  }
}

@media only screen and (max-width: 800px) {
  section:not(.home) > div:first-of-type {
    align-self: flex-start;
  }
}
