.footer {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: column;
    height: auto;
    min-height: 70px;
    padding: 15px;
    text-align: center;
    color: var(--light-slate);
    font-size: 12px;
    line-height: 1;
    letter-spacing: 0.7px;
    p {
        margin: 13px 0 3px 0;
        font-size: 13px;
    }
    small {
        font-size: 10px;
    }
    .footer-socials {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 10px;
        gap: 18px;
        a {
            display: inline-block;
            background-color: transparent;
            border: none;
        }
    }
}