#contact {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: 200px auto 100px;
    text-align: center;

    .whats-next {
        display: block;
        margin-bottom: 20px;
        color: var(--light-green);
        font-family: "mono";
        font-size: 16px;
        font-weight: 400;
        &::before {
            content: '03.';
            width: 0;
            height: 100%;
            left: -5px;
            position: relative;
            color: var(--light-green);
            font-size: clamp(7px, 3.2vw, 15px);
            font-weight: 400;
            font-family: 'mono';
        }
    }
    .get-in-touch {
        font-size: clamp(40px, 5vw, 55px);
        margin: 0px 0px 10px;
        font-weight: 900;
        color: var(--lightest-slate);
        line-height: 1.1;
        font-family: SansBold;
    }
    .contact-description {
        font-size: 18px;
    }
    .contact-button {
        margin: 50px auto 0px;
        background-color: transparent;
        color: var(--light-green) !important;
        border-color: var(--light-green);
        border: 1px solid var(--light-green);
        border-radius: 4px;
        padding: 1.25rem 1.75rem;
        line-height: 1;
        max-width: 50%;
        font-family: mono;
        font-size: 14px;
    }
}