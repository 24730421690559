/* The side navigation menu */
.sidenav {
    height: 100vh; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 7; /* Stay on top */
    top: 0; /* Stay at the top */
    right: 0;
    background-color: var(--light-navy); /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 100px; /* Place content 60px from the top */
    transition: 0.15s; /* 0.15 second transition effect to slide in the sidenav */
    backdrop-filter: blur(10px);
    flex-direction: column;
    justify-content: space-evenly;
    button {
      margin: 20px;
      padding: 5px;
      text-decoration: none;
      font-size: 22px;
      color: var(--lightest-slate);
      background-color: transparent;
      border: none;
      display: flex;
      align-self: center;
      transition-duration: 20ms;
      flex-direction: column-reverse;
      align-items: stretch;
      text-align: center;
      width: 100%;
      width: -moz-available;          /* WebKit-based browsers will ignore this. */
      width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
      width: fill-available;
      &:hover {
        cursor: pointer;
        color: var(--light-green) !important;
      }
      &:focus {
        color: var(--light-green) !important;
        
      }
    }
    .sidebar-resume-button {
      color: var(--light-green);
      background-color: transparent;
      border: 1px solid var(--light-green);
      border-radius: 4px;
      padding: 20px 30px;
      line-height: 1;
      text-decoration: none;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      display: block;
      width: 40%;
      margin: 30px auto 0 auto;
    }
    & > li:first-of-type {
      padding-top: 2rem;
    }
    li {
      display:flex;
      flex-direction: column-reverse;
      @for $i from 1 through 4 {
        &:nth-child(#{$i}) > button::after {
          content: '0#{$i}.';
          width: 0;
          height: 100%;
          left: -12px;
          margin-bottom: 10px;
          position: relative;
          color: var(--light-green);
          font-size: clamp(7px, 3.2vw, 15px);
          font-weight: 400;
          font-family: 'mono';
          font-size: 17px;
          align-self: center;
          display: flex !important;
        }
      }
    }
  }


.skip-nav-link {
  position: absolute;
  transform: translateY(-120%);
  filter: none;
  backdrop-filter: none;
  padding: 1.25rem 1.75rem;
  border-radius: 0 0 0.25em 0.25em;
  border: 1px solid var(--light-green);
  left: 0.5rem;
  transition: transform 325ms ease-in;
  line-height: 1;
  font-size: 14px;
  font-family: Sans;
  z-index: 20;
  background-color: var(--light-green);
  &:focus {
    transform: translateY(0);
  }
}

nav {
  $navbarHeight: 80px;

  opacity: 1;
  transform: none;
  box-shadow: 0 10px 30px -10px var(--navy-shadow);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  position: sticky !important;
  display: flex;
  font-family: Sans;
  letter-spacing: 0.8px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  top: 0px;
  padding: 0px 50px;
  width: 100%;
  height: $navbarHeight;
  filter: none !important;
  pointer-events: auto !important;
  user-select: auto !important;
  &.navbar {
    padding-block: 0;
    z-index: 5;
  }
  &.is-hidden {
    transform: translate(0, -$navbarHeight - 10);
    transition: transform 200ms ease;
  }
  &.is-visible {
    transform: translate(0, 0);
    transition: transform 200ms ease;
  }
  .nav-btn {
    position: relative;
    z-index: 20;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    margin-right: -15px;
    padding: 15px;
    border: 0px;
    background-color: transparent;
    color: inherit;
    text-transform: none;
    transition-timing-function: linear;
    transition-duration: 0.15s;
    transition-property: opacity, filter;
  }
  .nav-link {
    color: var(--lightest-slate) !important;
    padding: 10px !important;
    font-size: 13px;
    letter-spacing: 1.2px;
    cursor: pointer;
    background-color: transparent;
    border: none;
  }
  .nav-link:hover,  
  .nav-link:focus {
    color: var(--light-green) !important;
  }
  .navbar-brand {
    padding-top: 0.9125rem;
    color: var(--light-green) !important;
    cursor: pointer;
    background-color: transparent;
    border: none;
  }
  .resume-button {
    color: var(--light-green);
    background-color: transparent;
    border: 1px solid var(--light-green);
    border-radius: 4px;
    padding: 0.75rem 1rem;
    line-height: 1;
    text-decoration: none;
    cursor: pointer;
    margin-right: 50px;
    font-size: 13px;
    display: block;
    transition: background-color 350ms ease;
  }
  .resume-button:hover, .resume-button:focus {
    background-color: var(--light-green-low-alpha);
    transition: background-color 350ms ease;
  }

  .navbar-nav {
    align-items: end;
    flex-direction: row;
    gap: 20px;
  }
  @for $i from 1 through 3 {
    .navbar-nav li:nth-child(#{$i}) {
        display: flex;
        gap: 15px;
        &::before {
          content: '0#{$i}.';
          width: 0;
          height: 100%;
          left: -5px;
          position: relative;
          color: var(--light-green);
          font-size: clamp(6px, 3.2vw, 12px);
          font-weight: 400;
          font-family: 'mono';
          align-self: center;
          display: flex !important;
        }
      }
    } 
  }

#signature {
  stroke-dasharray: 2902;
  stroke-dashoffset: 2902;
  animation: sign 4s ease;
  animation-fill-mode: forwards;
}

@keyframes sign {
  to {
    stroke-dashoffset: 0;
  }
}

.ham-box {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 24px;

  .ham-box-inner {
      position: absolute;
      top: 50%;
      right: 0px;
      width: 30px;
      height: 2px;
      border-radius: 4px;
      background-color: var(--light-green);
      transition: transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  }
}
.ham-box-inner::before, .ham-box-inner::after {
  content: "";
  display: block;
  position: absolute;
  left: auto;
  right: 0px;
  width: 30px;
  height: 2px;
  border-radius: 4px;
  background-color: var(--light-green);
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform;
}

.ham-box-inner::before {
  content: "";
  opacity: 1;
  width: 120%;
  top: -10px;
}
.ham-box-inner::after {
  content: "";
  display:block;
  opacity: 1;
  width: 80%;
  bottom: -10px;
  transform: rotate(0deg);
}

.changed .ham-box-inner::after {
  width: 100%;
  bottom: 0px;
  transform: rotate(-90deg);
}

.changed .ham-box-inner::before {
  width: 100%;
  top: 0px;
  opacity: 0;
}

.changed .ham-box-inner {
  transform: rotate(225deg);
}


/* 801 and up */
@media only screen and (min-width: 801px) {
    .sidenav {
      width: 0 !important;
    }
    #root > *:not(aside) {
      filter: none !important;
    }
    body {
      overflow: inherit !important;
    }
}

/* 800 and down */
@media only screen and (max-width: 800px) {
  .navbar {
    padding: 0px 25px 0 0 !important;
  }
}