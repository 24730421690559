.side-socials {
    width: 40px;
    position: fixed;
    display: flex;
    bottom: 98px;
    left: 40px;
    right: auto;
    z-index: 10;
    color: var(--light-slate);
    margin-bottom: 18px;

    ul {
        display: flex;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
        padding: 0px;
    }
    
    button, a{
        display: block;
        background-color: transparent;
        border: none;
        padding: 10px;
        color: var(--light-slate);
    }

    li:last-of-type::after {
        content: '';    
        width: 0;
        height: 115px;
        position: absolute;
        border: 0.5px solid var(--light-slate);
        top: 163px;
        left: 50%;
    }
}

.side-email {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    right: 0%;
    bottom: 13.4rem;
    cursor: pointer;
    font-size: 15px;
    a {
        text-decoration: none;
        color: var(--light-slate);
        transition: all 200ms ease;
    }
    a:hover {
        transform: translateX(-2px);
        color: var(--light-green);
    }
    a:focus {
        transform: translateX(-2px);
        color: var(--light-green);
    }
    &::after {
        content: '';   
        width: 0;
        height: 120px;
        position: absolute;
        border: 0.5px solid var(--light-slate);
        transform: rotate(270deg) translateY(190px);
        top: -252%;
        left: 50%;
        cursor: default;
    }
}

.rotate {
    transform: rotate(90deg);
}

