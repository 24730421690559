:root {
  --light-green: hsl(166, 100%, 70%);
  --light-green-low-alpha: hsl(166, 100%, 70%, 0.1);
  --slate: hsl(225, 20%, 61%);
  --light-slate: hsl(225, 31%, 74%);
  --lightest-slate: hsl(226, 70%, 88%);
  --navy: hsl(216, 65%, 11%);
  --light-navy: hsl(218, 58%, 16%);
  --lightest-navy: hsl(218, 41%, 23%);
  --navy-shadow: hsla(216, 86%, 6%, 0.7);
}

@font-face {
  font-family: 'mono';
  src: url('./fonts/BPmono.ttf') format('truetype');
}
@font-face {
  font-family: 'Sans';
  src: url('./fonts/DMSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'SansBold';
  src: url('./fonts/DMSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'SansBoldItalic';
  src: url('./fonts/DMSans-BoldItalic.ttf') format('truetype');
}

dl,
ol,
ul {
  margin: 0;
  padding: 0;
}

section {
  padding: 100px 0px;
}

h2 {
  margin: 0;
}

ul,
li {
  list-style: none;
}

html {
  scroll-behavior: smooth;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

body {
  margin: 0px;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  color: var(--slate);
  font-family: 'Sans';
  font-size: 19px;
  line-height: 1.3;
  background-color: var(--navy);
}

.invis {
  display: none;
}
.show {
  display: block;
  animation: fadeIn 500ms ease forwards;
}
.hide {
  opacity: 0;
}
.show-instant {
  display: flex !important;
  flex-direction: column;
  animation: slide-down 200ms ease forwards;
}

@keyframes slide-down {
  0% {
    -webkit-transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateX(0%);
  }
}

@keyframes slide-in {
  0% {
    -webkit-transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0%);
  }
}

.light-blue-color {
  color: var(--main-bg-color-inset);
}

.light-slate-color {
  color: var(--lightest-slate);
}

.light-green-color {
  color: var(--light-green);
}

.big-heading {
  font-size: clamp(40px, 8vw, 70px);
  font-family: SansBold;
  font-weight: 900;
}

::-webkit-scrollbar {
  width: 0.25em;
}

::-webkit-scrollbar-track {
  background: var(--navy);
  border-radius: 100vw;
}

::-webkit-scrollbar-thumb {
  background: hsla(225, 20%, 61%, 0.5);
  border-radius: 100vw;
}
::-webkit-scrollbar-thumb:hover {
  background: hsla(225, 20%, 61%, 0.8);
}

@supports (scrollbar-color: var(--slate) var(--navy)) {
  * {
    scrollbar-color: hsla(225, 20%, 61%, 0.5) var(--navy);
    scrollbar-width: thin;
  }
}

@for $i from 1 through 12 {
  $j: $i - 1;
  $k: $j * 2;
  $l: $j * 2 * 2;
  .fade-in-#{$i} {
    opacity: 0;
    animation: fadeIn 400ms;
    animation-fill-mode: forwards;
    @if $i >= 6 {
      animation-delay: #{$k}00ms;
    } @else if $i > 10 {
      animation-delay: #{$l}00ms;
    } @else {
      animation-delay: #{$j}00ms;
    }
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    transition-delay: 0ms;
    visibility: visible;
    opacity: 0;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 20, 0, 1);
  }
  100% {
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s,
      opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s,
      transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    visibility: visible;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}

@-moz-keyframes fadeIn {
  0% {
    transition-delay: 0ms;
    visibility: visible;
    opacity: 0;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 20, 0, 1);
  }
  100% {
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s,
      opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s,
      transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    visibility: visible;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}

@-webkit-keyframes fadeIn {
  0% {
    transition-delay: 0ms;
    visibility: visible;
    opacity: 0;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 20, 0, 1);
  }
  100% {
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s,
      opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s,
      transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    visibility: visible;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}

@-o-keyframes fadeIn {
  0% {
    transition-delay: 0ms;
    visibility: visible;
    opacity: 0;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 20, 0, 1);
  }
  100% {
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s,
      opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s,
      transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    visibility: visible;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}

@-ms-keyframes fadeIn {
  0% {
    transition-delay: 0ms;
    visibility: visible;
    opacity: 0;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 20, 0, 1);
  }
  100% {
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s,
      opacity 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s,
      transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    visibility: visible;
    opacity: 1;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}
